(function () {
  app.controller("landingCtrl", [
    "$scope",
    "$timeout",
    "$analytics",
    function ($scope, $timeout, $analytics) {
      $scope.init = function () {};

      $scope.setPageTrack = function (pageUrl, pageTitle = "") {
        $analytics.pageTrack(pageUrl);
        window.document.title = pageTitle;
      };

      $scope.showEventLeadCapturePopup = function () {
        $.get(
          `/events/${$scope.event.slug}/show_lead_capture_popup`,
          {},
          function (rs) {}
        );
      };

      $scope.showEventPasswordPopup = function () {
        $.get(
          `/events/${$scope.event.slug}/show_password_popup`,
          {},
          function (rs) {}
        );
      };
    },
  ]);
}.call(this));

require("angulartics");

const angularDragula = require("angularjs-dragula");

(function () {
  var app = angular.module("app", [
    "nvd3",
    "ngSanitize",
    "angulartics",
    angularDragula(angular),
    require("angulartics-google-tag-manager"),
  ]);

  const currenciesSymbolsMap = {
    USD: "$",
    CAD: "CAD ",
    EUR: "€",
  };

  $(document).on("turbolinks:load", function () {
    angular.bootstrap(document.body, ["app"]);
  });

  app.filter("maskString", function () {
    return function (str) {
      if (!str) {
        return "******";
      }

      return str.replace(/./g, "*").trim();
    };
  });

  app.filter("unsafe", function ($sce) {
    return function (val) {
      return $sce.trustAsHtml(val);
    };
  });

  app.filter("formatPhone", function () {
    return function (tel) {
      if (!tel) {
        return "";
      }

      var value = tel.toString().trim().replace(/^\+/, "");

      if (value.match(/[^0-9]/)) {
        return tel;
      }

      var country, city, number;

      switch (value.length) {
        case 10: // +1PPP####### -> C (PPP) ###-####
          country = 1;
          city = value.slice(0, 3);
          number = value.slice(3);
          break;

        case 11: // +CPPP####### -> CCC (PP) ###-####
          country = value[0];
          city = value.slice(1, 4);
          number = value.slice(4);
          break;

        case 12: // +CCCPP####### -> CCC (PP) ###-####
          country = value.slice(0, 3);
          city = value.slice(3, 5);
          number = value.slice(5);
          break;

        default:
          return tel;
      }

      if (country == 1) {
        country = "";
      }

      number = number.slice(0, 3) + "-" + number.slice(3);

      return (country + " " + city + "-" + number).trim();
    };
  });

  app.filter("noFractionCurrency", [
    "$filter",
    "$locale",
    function (filter, locale) {
      var currencyFilter = filter("currency");
      var formats = locale.NUMBER_FORMATS;
      return function (amount, currencySymbol) {
        var value = currencyFilter(amount, currencySymbol);
        var sep = value.indexOf(formats.DECIMAL_SEP);
        if (value.substring(sep + 1) !== "00") {
          return value;
        }

        if (amount >= 0) {
          return value.substring(0, sep);
        }
        return value.substring(0, sep) + ")";
      };
    },
  ]);

  // auto add currency symbol and foramt price (for CAD and EUR)
  app.filter("formatPrice", [
    "$filter",
    function (filter) {
      const currencyFilter = filter("currency");
      return function (amount, params) {
        let value = currencyFilter(amount, currenciesSymbolsMap[params]) + "";
        if (value.indexOf(".00") > -1 && params === "CAD") {
          value = value.replace(/\.00/g, "");
        }
        return value;
      };
    },
  ]);

  app.directive("onlyNum", function () {
    return function (scope, element, attrs) {
      var keyCode = [
        8, 9, 37, 39, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99,
        100, 101, 102, 103, 104, 105, 110,
      ];
      element.bind("keydown", function (event) {
        if ($.inArray(event.which, keyCode) == -1) {
          scope.$apply(function () {
            scope.$eval(attrs.onlyNum);
            event.preventDefault();
          });
          event.preventDefault();
        }
      });
    };
  });

  app.directive("onlyDecimal", function () {
    return function (scope, element, attrs) {
      var keyCode = [
        8, 9, 37, 39, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99,
        100, 101, 102, 103, 104, 105, 110, 190,
      ];
      element.bind("keydown", function (event) {
        if ($.inArray(event.which, keyCode) == -1) {
          scope.$apply(function () {
            scope.$eval(attrs.onlyNum);
            event.preventDefault();
          });
          event.preventDefault();
        }
      });
    };
  });

  app.directive("smartDecimal", function () {
    return {
      require: "ngModel",
      link: function (scope, element, attrs, ngModel) {
        scope.$watch(attrs.ngModel, function (newValue, oldValue) {
          var spiltArray = String(newValue).split("");

          if (spiltArray.length === 0) return;
          if (
            spiltArray.length === 1 &&
            (spiltArray[0] == "-" || spiltArray[0] === ".")
          )
            return;
          if (spiltArray.length === 2 && newValue === "-.") return;

          if (attrs.max) {
            var maxValue = parseFloat(attrs.max);
            if (maxValue < parseFloat(newValue)) {
              newValue = maxValue;
              ngModel.$setViewValue(newValue);
              ngModel.$render();
            }
          }

          if (attrs.min) {
            var minValue = parseFloat(attrs.min);
            if (minValue > parseFloat(newValue)) {
              newValue = minValue;
              ngModel.$setViewValue(newValue);
              ngModel.$render();
            }
          }

          if (attrs.allowNegative == "false") {
            if (spiltArray[0] == "-") {
              newValue = newValue.replace("-", "");
              ngModel.$setViewValue(newValue);
              ngModel.$render();
            }
          }

          if (attrs.allowDecimal == "false") {
            newValue = parseInt(newValue);
            ngModel.$setViewValue(newValue);
            ngModel.$render();
          }

          if (attrs.allowDecimal != "false") {
            var fractionNumber = 2;
            if (attrs.decimalUpto) {
              fractionNumber = attrs.decimalUpto;
            }

            var n = String(newValue).split(".");
            if (n[1]) {
              var n2 = n[1].slice(0, fractionNumber);
              newValue = [n[0], n2].join(".");
              ngModel.$setViewValue(newValue);
              ngModel.$render();
            }
          }

          /*Check it is number or not.*/
          if (isNaN(newValue)) {
            ngModel.$setViewValue(oldValue);
            ngModel.$render();
          }
        });
      },
    };
  });

  app.directive("inputMaxLengthNumber", function () {
    return {
      require: "ngModel",
      restrict: "A",
      link: function (scope, element, attrs, ngModelCtrl) {
        function fromUser(text) {
          var maxlength = Number(attrs.maxlength);
          if (String(text).length > maxlength) {
            ngModelCtrl.$setViewValue(ngModelCtrl.$modelValue);
            ngModelCtrl.$render();
            return ngModelCtrl.$modelValue;
          }
          return text;
        }
        ngModelCtrl.$parsers.push(fromUser);
      },
    };
  });

  app.directive("multiLineEllipsis", function () {
    return {
      restrict: "A",
      link: function (scope, element, attrs) {
        scope.$evalAsync(() => {
          const toggleEllipsisBtn = $(
            '<button class="btn btn-link p-0">Show more</button>'
          );
          const isEllipsised = () => {
            return element[0].scrollHeight > element[0].clientHeight;
          };
          const ellipsisCSSAttr = {
            display: "-webkit-box",
            overflow: "hidden",
            "text-overflow": "ellipsis",
            "-webkit-line-clamp": attrs.multiLineEllipsis || 1,
            "line-clamp": attrs.multiLineEllipsis || 1,
            "-webkit-box-orient": "vertical",
            height: "unset",
          };
          element.css(ellipsisCSSAttr);
          if (isEllipsised()) {
            element.after(toggleEllipsisBtn);
          }
          const originalClientHeight = element.height();
          const originalScrollHeight = element[0].scrollHeight;
          toggleEllipsisBtn.on("click", function () {
            if (isEllipsised()) {
              toggleEllipsisBtn.text("Show less");
              element.animate(
                {
                  height: originalScrollHeight,
                },
                () => {
                  element.css({
                    display: "initial",
                    overflow: "initial",
                    "text-overflow": "initial",
                  });
                }
              );
            } else {
              toggleEllipsisBtn.text("Show more");
              element.animate(
                {
                  height: originalClientHeight,
                },
                () => {
                  element.css(ellipsisCSSAttr);
                }
              );
            }
          });
        });
      },
    };
  });

  app.controller("GlobalCtrl", [
    "$scope",
    "$timeout",
    "$sce",
    "$compile",
    function ($scope, $timeout, $sce, $compile) {
      const payment_gateways_map = {
        PRECZN: 4,
        STRIPE: 1,
      };

      $scope.compile_content = function (data) {
        $compile(data)($scope);
        $scope.$apply();
      };

      $scope.validatePrecznCreditCard = function (credit_card) {
        const isCardNameInvalid =
          ($.trim(credit_card.name) == "" ||
            credit_card.name.replace(/ /g, "") == "") &&
          !$(".preczn-form-input.cc-number").hasClass("complete");

        credit_card.error_message = null;
        if (isCardNameInvalid) {
          credit_card.error_message = "Card Name is invalid";
          return credit_card;
        }

        var card_number = $(".preczn-form-input.cc-number").hasClass(
          "complete"
        );
        if (!card_number) {
          credit_card.error_message = "Card Number is invalid";
          return credit_card;
        }

        var card_expiration = $(".preczn-form-input.cc-exp").hasClass(
          "complete"
        );
        if (!card_expiration) {
          credit_card.error_message = "Card Expiration is invalid";
          return credit_card;
        }

        var card_cvv = $(".preczn-form-input.cc-cvc").hasClass("complete");
        if (!card_cvv) {
          credit_card.error_message = "Card CVV is invalid";
          return credit_card;
        }

        return credit_card;
      };

      $scope.validateCreditCard = function (credit_card) {
        credit_card.error_message = null;

        if (
          $.trim(credit_card.name) == "" ||
          credit_card.name.replace(/ /g, "") == ""
        ) {
          credit_card.error_message = "Card Name is invalid";
          return credit_card;
        }

        var card_number = $("#card_number").hasClass("complete");
        if (!card_number) {
          credit_card.error_message = "Card Number is invalid";
          return credit_card;
        }

        var card_expiration = $("#card_expiration").hasClass("complete");
        if (!card_expiration) {
          credit_card.error_message = "Card Expiration is invalid";
          return credit_card;
        }

        var card_cvv = $("#card_cvv").hasClass("complete");
        if (!card_cvv) {
          credit_card.error_message = "Card CVV is invalid";
          return credit_card;
        }

        return credit_card;
      };

      $scope.submitCreditCard = function (
        credit_card,
        payment_gateway,
        callbackFn
      ) {
        if ($.trim(credit_card.error_message) == "") {
          const tokenData = { name: credit_card.name };

          // Try to add additional user context: zip code and country code
          const zipCodeFromForm = angular
            .element('[ng-model="billing_address.zipcode"]')
            .val();
          const countryFromForm = angular
            .element('[ng-model="billing_address.country_id"]')
            .val();

          if (countryFromForm) {
            const countryId = countryFromForm.split(":")[1]; // it looks like number:23
            const country = COUNTRIES.filter(
              (e) => e.id == parseInt(countryId)
            )[0];

            if (country && country.abbr) {
              tokenData.address_country = country.abbr;
            }
          }

          if (zipCodeFromForm) {
            tokenData.address_zip = zipCodeFromForm;
          }

          if (payment_gateway === payment_gateways_map.PRECZN) {
            Preczn.PaymentFields.getToken(
              window.preczn_public_api_key,
              "paymentPrecznForm",
              function (result) {
                $timeout(function () {
                  if (result.errors) {
                    // result.errors is an array of strings
                    credit_card.error_message = Array.isArray(result.errors)
                      ? result.errors.join(",")
                      : "Failed to validate!";
                  } else {
                    credit_card.preczn_single_use_token = result.token;
                    credit_card.last4_digit = result.last4;
                  }
                  callbackFn(credit_card);
                });
              }
            );
          } else if (payment_gateway === payment_gateways_map.STRIPE) {
            stripe
              .createToken(credit_card.card_number, tokenData)
              .then(function (result) {
                $timeout(function () {
                  if (result.error) {
                    credit_card.error_message = result.error.message;
                  } else {
                    credit_card.token = result.token.id;
                    credit_card.last4_digit = result.token.card.last4;
                  }
                  callbackFn(credit_card);
                });
              });
          }
        }

        return credit_card;
      };

      $scope.convertHTML = function (content) {
        return $sce.trustAsHtml(content);
      };
    },
  ]);

  app
    .directive("phonenumberDirective", [
      "$filter",
      function ($filter) {
        /*
		Intended use:
			<phonenumber-directive placeholder='prompt' model='someModel.phonenumber'></phonenumber-directive>
		Where:
			someModel.phonenumber: {String} value which to bind only the numeric characters [0-9] entered
				ie, if user enters 617-2223333, value of 6172223333 will be bound to model
			prompt: {String} text to keep in placeholder when no numeric input entered
		*/

        function link(scope, element, attributes) {
          // scope.inputValue is the value of input element used in template
          scope.inputValue = scope.phonenumberModel;

          scope.$watch("inputValue", function (value, oldValue) {
            value = String(value);
            var number = value.replace(/[^0-9]+/g, "");
            scope.phonenumberModel = number;
            scope.inputValue = $filter("phonenumber")(number);
          });
        }

        return {
          link: link,
          restrict: "E",
          scope: {
            phonenumberPlaceholder: "=placeholder",
            phonenumberModel: "=model",
            inputValue: "=model",
            disabled: "=disabled",
          },
          template:
            '<input ng-disabled="disabled" class="form-control" ng-model="inputValue" ng-value="value" type="tel" class="phonenumber" placeholder="{{phonenumberPlaceholder}}" title="Phone Number (Format: (999) 9999-9999)">',
        };
      },
    ])

    .filter("phonenumber", function () {
      /*
	    Format phonenumber as: c (xxx) xxx-xxxx
	    	or as close as possible if phonenumber length is not 10
	    	if c is not '1' (country code not USA), does not use country code
	    */

      return function (number) {
        /*
		    @param {Number | String} number - Number that will be formatted as telephone number
		    Returns formatted number: (###) ###-####
		    	if number.length < 4: ###
		    	else if number.length < 7: (###) ###
		    Does not handle country codes that are not '1' (USA)
		    */
        if (!number) {
          return "";
        }

        number = String(number);

        // Will return formattedNumber.
        // If phonenumber isn't longer than an area code, just show number
        var formattedNumber = number;

        // if the first character is '1', strip it out and add it back
        //var c = (number[0] == '1') ? '1 ' : '';
        //number = number[0] == '1' ? number.slice(1) : number;

        // # (###) ###-#### as c (area) front-end
        var area = number.substring(0, 3);
        var front = number.substring(3, 6);
        var end = number.substring(6, 10);

        if (front) {
          //formattedNumber = (c + "(" + area + ") " + front);
          formattedNumber = "(" + area + ") " + front;
        }
        if (end) {
          formattedNumber += "-" + end;
        }
        return formattedNumber;
      };
    });

  app
    .directive("zipcodeDirective", [
      "$filter",
      function ($filter) {
        /*
		Intended use:
			<zipcode-directive placeholder='prompt' model='someModel.zipcode'></zipcode-directive>
		Where:
			someModel.zipcode: {String} value which to bind only the numeric characters [0-9] entered
				ie, if user enters 35816, value of 35816 will be bound to model
			prompt: {String} text to keep in placeholder when no numeric input entered
		*/

        function link(scope, element, attributes) {
          // scope.inputValue is the value of input element used in template
          scope.inputValue = scope.zipcodeModel;

          scope.$watch("inputValue", function (value, oldValue) {
            value = String(value);
            var number = value.replace(/[^0-9]+/g, "");
            scope.zipcodeModel = number;
            scope.inputValue = $filter("zipcode")(number);
          });
        }

        return {
          link: link,
          restrict: "E",
          scope: {
            zipcodePlaceholder: "=placeholder",
            zipcodeModel: "=model",
            inputValue: "=model",
            disabled: "=disabled",
          },
          template:
            '<input ng-disabled="disabled" class="form-control" ng-model="inputValue" type="text" inputmode="numeric" class="zipcode" placeholder="{{zipcodePlaceholder}}" title="ZIP code (Format: 12345 or 12345-1234)">',
        };
      },
    ])

    .filter("zipcode", function () {
      return function (number) {
        /*
		    @param {Number | String} number - Number that will be formatted as ZIP code
		    Returns formatted number: ##### | #####-####
		    	if number.length < 6: #####
		    	else if number.length < 10: #####-####
		    */
        if (!number) {
          return "";
        }

        number = String(number);

        // Will return formattedNumber.
        // If ZIP code isn't longer than a normal ZIP code, just show number
        var formattedNumber = number;

        // ##### as ZIP code, #####-#### as ZIP-add_ons
        var zip = number.substring(0, 5);
        var add_ons = number.substring(5, 9);

        if (add_ons) {
          //formattedNumber = (zip-add_ons);
          formattedNumber = zip + "-" + add_ons;
        } else {
          formattedNumber = zip;
        }
        return formattedNumber;
      };
    });

  this.app = app;
}.call(this));

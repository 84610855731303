export default class ClipboardCopy {
  static successMessage = 'Copied to clipboard'

  static href = (id) => {
    const element = document.getElementById(id)

    if (!element) return

    const contentToCopy = element.href

    navigator.clipboard.writeText(contentToCopy).then(() => {
      toastr.success(this.successMessage)
    })
  }
}

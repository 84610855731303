(function () {
    app.controller('RepresentCtrl', ['$scope', '$timeout', '$analytics', '$http', '$interval', function ($scope, $timeout, $analytics, $http, $interval) {

        $scope.fileList = [];
        $scope.event = null;
        $scope.keywords = '';
        $scope.user = null;
        $scope.evidenceType = {
            active: 'cancellation_policy',
            options: [
                {value: 'cancellation_policy', label: 'Cancellation policy', disabled: false},
                {value: 'customer_communication', label: 'Customer communication', disabled: false},
                {value: 'customer_signature', label: 'Customer signature', disabled: false},
                {value: 'duplicate_charge_documentation', label: 'Duplicate charge document', disabled: false},
                {value: 'receipt', label: 'Receipt', disabled: false},
                {value: 'refund_policy', label: 'Refund policy', disabled: false},
                {value: 'service_documentation', label: 'Service documentation', disabled: false},
                {value: 'shipping_documentation', label: 'Shipping documentation', disabled: false},
                {value: 'uncategorized_file', label: 'Other', disabled: false},
            ]
        }
        $scope.evidenceUploadFiles = [];

        $scope.getUserName = () => $scope.user?.first_name + ' ' + $scope.user?.last_name;

        $scope.onReady = function () {
            $scope.updateFileList();
        };

        $scope.getFolderName = function () {
            return `events/${String($scope.event && $scope.event.name || '')}`;
        }

        $scope.deleteFile = function (file) {
            bootbox.confirm(
                'Are you sure delete?',
                async function (rs) {
                    if (rs) {
                        try {
                            await window.evidenceApi.deleteEventEvidence({
                                event_name: $scope.event.name,
                                evidence_id: file.id
                            })
                            toastr.success("Deleted");
                            await $scope.updateFileList();
                        } catch (err) {
                            console.warn(err);
                            toastr.error("Failed to delete");
                        }
                    }
                })
        }

        $scope.downloadFile = async (file) => {
            try {
                $('.loading').fadeIn();
                const res = await window.evidenceApi.getDownloadUrl({path: file.path});
                const url = res.data?.url
                const blobUrl = await fetch(url).then(r => r.blob()).then(blobFile => URL.createObjectURL(blobFile));
                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = file.name;
                a.click();
                a.remove();
            } catch (err) {
                toastr.error("Failed to download" + err);
            } finally {
                $('.loading').fadeOut();
            }
        }

        $scope.previewFile = async (file) => {
            const res = await window.evidenceApi.getDownloadUrl({path: file.path});
            const url = res.data?.url
            window.open(url, '_blank');
        }

        $scope.handleAddEvidenceClick = function () {
            $('#represent-modal').modal('show');
            $('.loading').fadeOut();
        }

        $scope.onEvidenceUploadFileSelected = function (inputEl) {
            const fileList = Array.from(inputEl.files);
            if (!fileList.length) return;
            $scope.evidenceUploadFiles.push(...fileList);
            $scope.$apply();
        }

        // for upload modal --> remove button
        $scope.removeEvidenceUploadFile = function (file) {
            const index = $scope.evidenceUploadFiles.indexOf(file);
            if (index < 0) return;
            $scope.evidenceUploadFiles.splice(index, 1);
            if (!$scope.$$phase) $scope.$apply();
        }

        $scope.uploadEvidenceFiles = async function () {
            if (!$scope.evidenceUploadFiles.length) return;

            const newFileList = $scope.evidenceUploadFiles.map(file => {
                const [name, ext] = file.name.match(/(.+)\.(\w+)$/).slice(1);
                const hashFilename = window.cyrb53(name) + '.' + ext
                return {
                    ...file,
                    name: hashFilename,
                    ext,
                    path: $scope.getFolderName() + '/' + hashFilename,
                    hashFilename: hashFilename,
                    sourceFilename: file.name,
                    source: file
                }
            })

            if (!newFileList.length) return;
            try {
                await window.disputeAwsUtils.uploadFiles({
                    fileList: newFileList,
                    folderName: $scope.getFolderName()
                })

                await Promise.all(newFileList.map(item => {
                    return window.evidenceApi.addEventEvidence({
                        event_name: $scope.event.name,
                        event_id: $scope.event.id,
                        path: item.path,
                        name: item.sourceFilename,
                        added_by: $scope.getUserName(),
                        file_format: item.ext || 'null',
                        type: $scope.evidenceType.active
                    })
                }));
                toastr.success("Uploaded");
                await $scope.updateFileList();
                $scope.$apply();
            } catch (err) {
                console.warn(err);
                toastr.error("Failed to upload");
            }
        }

        $scope.updateFileList = async function (keywords = '') {
            $('.loading').fadeIn();
            try {
                const res = await window.evidenceApi.getEventEvidenceList({
                    event_name: $scope.event.name,
                    event_id: $scope.event.id,
                });

                const disabledEvidenceTypeOptions = new Set()
                const fileList = res?.data?.evidences?.map((file) => {
                    disabledEvidenceTypeOptions.add(file.type)
                    return {
                        ...file,
                        name: file.name,
                        ext: file.file_format || 'null',
                        createdAt: moment(file.created_at).format('YYYY-MM-DD'),
                        path: file.s3_path,
                        typeFormatName: $scope.evidenceType.options.find(item => item.value === file.type)?.label || 'Other'
                    };
                }) ?? []

                // update evidenceType options disabled
                let firstActiveType = null;
                $scope.evidenceType.options = $scope.evidenceType.options.map(item => {
                    const disabled = disabledEvidenceTypeOptions.has(item.value);
                    if (!firstActiveType && !disabled) firstActiveType = item.value;
                    return {
                        ...item,
                        disabled
                    }
                })
                $scope.evidenceType.active = firstActiveType;

                $scope.fileList = fileList.filter(item => item.name.toLowerCase().includes(keywords.toLowerCase()));
                $scope.$apply();
            } finally {
                $('.loading').fadeOut();
            }
        }

        $scope.search = function () {
            $scope.updateFileList($scope.keywords);
        }
    }]);
}.call(this));

(function () {
  app.controller("OrderRoomsSectionCtrl", [
    "$scope",
    "$timeout",
    "$analytics",
    "$http",
    "$interval",
    function ($scope, $timeout, $analytics, $http, $interval) {
      $scope.NAV_MEMBERS = "Members";
      $scope.NAV_ROOMS = "Rooms";
      $scope.navItems = [$scope.NAV_MEMBERS, $scope.NAV_ROOMS];
      $scope.currentNav = $scope.NAV_MEMBERS;
      $scope.resendHotelReservationNumberEmailOption = {
        value: "all",
      };

      $timeout(() => {
        $scope.clonedRooms = $.extend(true, [], $scope.rooms);
        let leaderName = "";
        let sequentialFlag = -1;
        $scope.clonedRooms.forEach((room) => {
          if (room.is_leader && !leaderName) {
            leaderName = room.member_name;
          }
        });
        $scope.membersMap = $scope.clonedRooms.reduce((prev, curr) => {
          if (curr.member_name === leaderName) {
            sequentialFlag += 1;
            if (sequentialFlag > 0) {
              curr.is_leader = false;
              curr.member_name = `${curr.member_name} (${sequentialFlag})`;
            }
          }
          prev[curr.id] = { ...curr };
          return prev;
        }, {});
        $scope.roomsMap = $scope.clonedRooms.reduce((prev, curr) => {
          if (!prev[curr.room_number] || curr.hotel_confirmation) {
            prev[curr.room_number] = {
              confirmationNumber: curr.hotel_confirmation,
            };
          }
          return prev;
        }, {});
      });

      $scope.switchNav = (navItem) => {
        $scope.currentNav = navItem;
      };

      $scope.getComposedData = () => {
        return Object.keys($scope.membersMap).map((id) => {
          const member = $scope.membersMap[id];
          return {
            split_order_id: Number(id),
            room_member_id: Number(id),
            name: member.member_name,
            email: member.email,
            confirmation_number:
              $scope.roomsMap[member.room_number].confirmationNumber,
          };
        });
      };

      $scope.hasDuplicateEmails = () => {
        const encounteredEmails = {};
        for (const id of Object.keys($scope.membersMap)) {
          const member = $scope.membersMap[id];
          if (member.email && encounteredEmails[member.email]) {
            return true;
          }
          encounteredEmails[member.email] = true;
        }
        return false;
      };

      $scope.update = async () => {
        if ($scope.isSplitOrder && $scope.hasDuplicateEmails()) {
          toastr.error(`There are members who have duplicate emails`);
          return;
        }
        await window.orderModifyApi.updateRoomMemberInfo({
          order_number: $scope.order?.order_number,
          guest_list: $scope.getComposedData(),
        });
        window.location.reload();
      };

      $scope.openResendHotelReservationNumberEmailModal = () => {
        $("#resend-hotel-reservation-number-email-modal").modal("show");
      };

      $scope.confirmResendHotelReservationNumberEmail = async () => {
        const { message } =
          (await window.orderModifyApi.resendHotelReservationNumberEmail({
            order_number: $scope.order?.order_number,
            resend_type: $scope.resendHotelReservationNumberEmailOption.value,
          })) || {};
        if (message) {
          toastr.success(message);
        }
      };
    },
  ]);
}.call(this));

window.evidenceApi = {
  getTemporaryToken() {
    return window.ajaxUtils.get("/disputes/evidences/s3_credentials");
  },
  getDownloadUrl({ path = "" }) {
    return window.ajaxUtils.get(
      `/disputes/evidences/presinged_url?s3_path=${encodeURIComponent(path)}`
    );
  },
  addEventEvidence({
    event_name = "",
    event_id = "",
    path = "",
    name = "",
    added_by = "",
    file_format = "",
    type = "",
  }) {
    return window.ajaxUtils.post(`/disputes/evidences`, {
      data: {
        s3_path: path,
        name,
        added_by,
        file_format,
        event_id,
        type,
        source: "portal_event",
      },
    });
  },
  getEventEvidenceList({ event_name = "", event_id = "" }) {
    return window.ajaxUtils.get(`/disputes/evidences/events/${event_id}`);
  },
  deleteEventEvidence({ event_name = "", evidence_id = "" }) {
    return window.ajaxUtils.delete(`/disputes/evidences/${evidence_id}`);
  },
  addOrderEvidence({
    order_number = "",
    event_id = "",
    path = "",
    name = "",
    added_by = "",
    source = "Order",
    file_format = "",
    type = "",
  }) {
    return window.ajaxUtils.post(`/disputes/evidences`, {
      data: {
        event_id,
        s3_path: path,
        name,
        added_by,
        source,
        file_format,
        type,
        order_number,
      },
    });
  },
  deleteOrderEvidence({ order_number = "", evidence_id = "" }) {
    return window.ajaxUtils.delete(`/disputes/evidences/${evidence_id}`);
  },
  getDisputeList({ order_number = "", event_id = "" }) {
    return window.ajaxUtils.get(
      `/disputes?event_id=${event_id}&order_number=${order_number}`
    );
  },
  sendRepresentment({
    order_number = "",
    dispute_id = "",
    event_id = "",
    platform = "stripe",
    defense_document_type_code = "",
    defense_reason_code = "",
  }) {
    return window.ajaxUtils.post(`/disputes/${dispute_id}/submit`, {
      data: {
        event_id,
        platform,
        defense_document_type_code,
        defense_reason_code,
      },
    });
  },
  retrieveAdyenInfo({ extern_id }) {
    return window.ajaxUtils.post("/disputes/adyen/retrieve_information", {
      data: {
        extern_id,
      },
    });
  },
};

window.disputeAwsUtils = {
  _uploadConfig: null, // if not null, aws is initialized
  _bucketS3Map: {},
  _defaultTryTimes: 3,
  reset() {
    this._uploadConfig = null;
    this._bucketS3Map = {};
  },
  async getUploadCredentials() {
    const { data } = await window.evidenceApi.getTemporaryToken();
    return data;
  },
  async init() {
    try {
      if (
        this._uploadConfig &&
        moment().isBefore(moment(this._uploadConfig.expiration), "minute")
      ) {
        return {
          uploadConfig: this._uploadConfig,
          s3: this.getS3(this._uploadConfig.bucketName),
        };
      }

      const credentials = await this.getUploadCredentials();
      const uploadConfig = {
        region: credentials.region,
        bucketName: credentials.bucket_name,
        accessKeyId: credentials.access_key_id,
        secretAccessKey: credentials.secret_access_key,
        sessionToken: credentials.session_token,
        expiration: credentials.expiration,
      };

      AWS.config.update({
        region: uploadConfig.region,
        credentials: new AWS.Credentials({
          accessKeyId: uploadConfig.accessKeyId,
          secretAccessKey: uploadConfig.secretAccessKey,
          sessionToken: uploadConfig.sessionToken,
        }),
      });
      this._uploadConfig = uploadConfig;
      return {
        uploadConfig: this._uploadConfig,
        s3: this.getS3(this._uploadConfig.bucketName),
      };
    } catch (e) {
      toastr.error("AWS: Failed to initialize AWS");
      console.warn(e);
    }
  },
  getS3(bucket) {
    if (this._bucketS3Map[bucket]) return this._bucketS3Map[bucket];
    const s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: { Bucket: bucket },
    });
    this._bucketS3Map[bucket] = s3;
    return s3;
  },
  createFileNamePrefix(folderName) {
    if (!folderName) return "";
    return `${folderName}/`;
  },
  createFileKey(folderName, fileName) {
    return `${this.createFileNamePrefix(folderName)}${fileName}`;
  },
  async uploadFiles({ folderName = "", fileList = [], tryCount = 0 }) {
    try {
      if (!fileList.length) return;
      const { uploadConfig, s3 } = await this.init();
      const uploads = fileList.map((file) => {
        const fileKey = this.createFileKey(folderName, file.name);
        const params = {
          Bucket: uploadConfig.bucketName,
          Key: fileKey,
          ContentType: file.type || file.source.type,
          Body: file.source || file,
        };
        return s3.upload(params).promise();
      });
      return Promise.all(uploads);
    } catch (e) {
      if (tryCount < this._defaultTryTimes) {
        // try again
        this.reset();
        return this.uploadFiles({
          folderName,
          fileList,
          tryCount: tryCount + 1,
        });
      }
      toastr.error("AWS: Failed to upload files");
      console.warn(e);
      throw e;
    }
  },
  async deleteFile({
    folderName = "",
    fileName = "",
    fileKey = "",
    tryCount = 0,
  }) {
    try {
      if (!fileName) return;
      const { uploadConfig, s3 } = await this.init();
      const params = {
        Bucket: uploadConfig.bucketName,
        Key: fileKey || this.createFileKey(folderName, fileName),
      };
      return s3.deleteObject(params).promise();
    } catch (e) {
      if (tryCount < this._defaultTryTimes) {
        // try again
        this.reset();
        return this.deleteFile({
          folderName,
          fileName,
          fileKey,
          tryCount: tryCount + 1,
        });
      }
      toastr.error("AWS: Failed to delete file");
      console.warn(e);
      throw e;
    }
  },
  async getFileList({ folderName = "", tryCount = 0 }) {
    try {
      const { uploadConfig, s3 } = await this.init();
      const params = {
        Bucket: uploadConfig.bucketName,
        Prefix: this.createFileNamePrefix(folderName),
      };
      return s3.listObjects(params).promise();
    } catch (e) {
      if (tryCount < this._defaultTryTimes) {
        // try again
        this.reset();
        return this.getFileList({ folderName, tryCount: tryCount + 1 });
      }
      toastr.error("AWS: Failed to get file list");
      console.warn(e);
      throw e;
    }
  },
  async getSearchFileList({ folderName = "", fileName = "", tryCount = 0 }) {
    try {
      const data = await this.getFileList({ folderName });
      if (!data.Contents.length) return [];
      if (!fileName) return data.Contents;
      const regex = new RegExp(fileName, "i");
      return data.Contents.filter((file) => {
        const filename = file.Key.replace(
          this.createFileNamePrefix(folderName),
          ""
        );
        return regex.test(filename);
      });
    } catch (e) {
      if (tryCount < this._defaultTryTimes) {
        // try again
        this.reset();
        return this.getSearchFileList({
          folderName,
          fileName,
          tryCount: tryCount + 1,
        });
      }
      toastr.error("AWS: Failed to get file list");
      console.warn(e);
      throw e;
    }
  },
  async getFileDownloadUrl({
    folderName = "",
    fileName = "",
    fileKey = "",
    tryCount = 0,
  }) {
    try {
      const { uploadConfig, s3 } = await this.init();
      const params = {
        Bucket: uploadConfig.bucketName,
        Key: fileKey || this.createFileKey(folderName, fileName),
      };
      return s3.getSignedUrlPromise("getObject", params);
    } catch (e) {
      if (tryCount < this._defaultTryTimes) {
        // try again
        this.reset();
        return this.getFileDownloadUrl({
          folderName,
          fileName,
          fileKey,
          tryCount: tryCount + 1,
        });
      }
      toastr.error("AWS: Failed to get file download url");
      console.warn(e);
      throw e;
    }
  },
  async getFolderList({ tryCount = 0 }) {
    try {
      const { uploadConfig, s3 } = await this.init();
      const params = {
        Bucket: uploadConfig.bucketName,
        Delimiter: "/",
      };
      return s3.listObjects(params).promise();
    } catch (e) {
      if (tryCount < this._defaultTryTimes) {
        // try again
        this.reset();
        return this.getFolderList({ tryCount: tryCount + 1 });
      }
      toastr.error("AWS: Failed to get folder list");
      console.warn(e);
      throw e;
    }
  },
  async getBucketList({ tryCount = 0 }) {
    try {
      const { s3 } = await this.init();
      return s3.listBuckets().promise();
    } catch (e) {
      if (tryCount < this._defaultTryTimes) {
        // try again
        this.reset();
        return this.getBucketList({ tryCount: tryCount + 1 });
      }
      toastr.error("AWS: Failed to get bucket list");
      console.warn(e);
      throw e;
    }
  },
};

(function () {
  app.controller("PayInstallmentCtrl", [
    "$scope",
    "$timeout",
    function ($scope, $timeout) {
      $scope.save_personal_info = true;
      $scope.payment_plans = [];
      $scope.credit_card = {};
      $scope.billing_address = {};
      $scope.shipping_address = {};
      $scope.billingStates = {};
      $scope.shippingStates = {};
      $scope.copy_billing_address = false;
      $scope.user_locale = 'en';
      $scope.event_currency = window.event_currency || 'USD';

      $scope.init = function () {};
      //========Watch=============
      $scope.$watchGroup(
        [
          "copy_billing_address",
          "billing_address.full_name",
          "billing_address.phone_number",
          "billing_address.address_1",
          "billing_address.address_2",
          "billing_address.city",
          "billing_address.zipcode",
          "billing_address.state_id",
        ],
        function () {
          if ($scope.copy_billing_address) {
            $scope.shipping_address = angular.copy($scope.billing_address);
          }
        }
      );
      //========== end ===============

      $scope.doPayment = function () {
        var isPrecznAndNewCard = $scope.event.payment_gateway == $scope.payment_gateways.PRECZN && $scope.credit_card.is_new_one;
        var isStripeAndNewCard = $scope.event.payment_gateway == $scope.payment_gateways.STRIPE && $scope.credit_card.is_new_one;
        var isBraintreeAndNewCard = $scope.event.payment_gateway == $scope.payment_gateways.BRAINTREE && $scope.credit_card.is_new_one;
        var isAuthorize_netAndNewCard = $scope.event.payment_gateway == $scope.payment_gateways.AUTHORIZE_NET && $scope.credit_card.is_new_one;

        $scope.error_message = "";
        if (!$scope.stripe_stress_test && isPrecznAndNewCard) {
          $scope.credit_card = $scope.validatePrecznCreditCard($scope.credit_card);

          if ($scope.credit_card.error_message) {
            $scope.error_message = $scope.credit_card.error_message;
            bootbox.alert($scope.error_message);
            return false;
          }

          $(".loading").fadeIn();

          var callbackFn = function (credit_card) {
            if ($.trim(credit_card.preczn_single_use_token) == '' || credit_card.error_message) {
              $scope.error_message = credit_card.error_message || 'Your Card is invalid!';
              $('.loading').fadeOut();
              bootbox.alert($scope.error_message);
              return false;
            }
  
            paymentExecute();
          }
          $scope.submitCreditCard($scope.credit_card, $scope.event.payment_gateway, callbackFn);
        } else if (!$scope.stripe_stress_test && isStripeAndNewCard) {
          $scope.credit_card = $scope.validateCreditCard($scope.credit_card);

          if ($scope.credit_card.error_message) {
            $scope.error_message = $scope.credit_card.error_message;
            bootbox.alert($scope.error_message);
            return false;
          }

          $(".loading").fadeIn();
          var callbackFn = function (credit_card) {
            if ($.trim(credit_card.token) == '' || credit_card.error_message) {
              $scope.error_message = credit_card.error_message || 'Your Card is invalid!';
              $('.loading').fadeOut();
              bootbox.alert($scope.error_message);
              return false;
            }
  
            paymentExecute();
          }
          $scope.submitCreditCard($scope.credit_card, $scope.event.payment_gateway, callbackFn);
        } else if (!$scope.stripe_stress_test && isBraintreeAndNewCard) {
          braintree.instance.requestPaymentMethod(function (
            requestPaymentMethodErr,
            payload
          ) {
            $scope.payment_method_nonce = payload.nonce;
            paymentExecute();
          });
        } else if (!$scope.stripe_stress_test && isAuthorize_netAndNewCard) {
          fuseAuthorizeNetCheck(({ valid, token }) => {
            if (valid) {
              $scope.payment_method_nonce = token.dataValue;
              paymentExecute();
            }
          });
        } else {
          paymentExecute();
        }
      };

      $scope.toggleCopyBillingAddress = function () {
        $scope.copy_billing_address = !$scope.copy_billing_address;
        $scope.shippingStates = $scope.billingStates;
        // reset these fields
        if (!$scope.copy_billing_address) {
          $scope.shipping_address = {};
        }
      };

      $scope.getBillingStates = function (country_id) {
        $.post(
          $scope.event_booking_path + "/get_states",
          { country_id: country_id },
          function (rs) {
            $timeout(function () {
              if (rs.success) {
                $scope.billingStates = rs.states;
              } else {
                console.error(rs.message);
              }
            });
          }
        );
      };

      $scope.getShippingStates = function (country_id) {
        $.post(
          $scope.event_booking_path + "/get_states",
          { country_id: country_id },
          function (rs) {
            $timeout(function () {
              if (rs.success) {
                $scope.shippingStates = rs.states;
              } else {
                console.error(rs.message);
              }
            });
          }
        );
      };

      //=================== Functionality ====================

      function paymentExecute() {
        var opt = {
          event_id: $scope.event.id,
          token: $scope.braintree.active
            ? $scope.credit_card.fingerprint
            : $scope.credit_card.token,
          preczn_single_use_token: (!$scope.credit_card.is_new_one && $scope.credit_card.preczn_multi_use_token) 
            ? null
            : $scope.credit_card.preczn_single_use_token,
          credit_card_id: $scope.credit_card.id,
          payment_method_nonce: $scope.payment_method_nonce,
          stripe_customer_id: $scope.credit_card.stripe_customer_id,
          card_name: $scope.credit_card.name,
          billing_address: $scope.billing_address,
          shipping_address: $scope.shipping_address,
        };

        if (typeof diagnostics_service != "undefined") {
          opt = Object.assign(opt, diagnostics_service);
        }

        $.post("/dashboard/pay_installment_execution", opt, function (rs) {
          $timeout(function () {
            if (rs.succeed) {
              location.href = "/dashboard";
            } else {
              if (
                $scope.event.payment_gateway ==
                $scope.payment_gateways.BRAINTREE
              ) {
                braintree.instance.clearSelectedPaymentMethod();
              }
              if (rs.message) {
                bootbox.alert(rs.message);
              }
            }
          });
        });
      }

      $(document).ready(function () {
        if (Object.keys($scope.billing_address).length > 1) {
          $scope.getBillingStates($scope.billing_address.country_id);
        }
        if (Object.keys($scope.shipping_address).length > 1) {
          $scope.getShippingStates($scope.shipping_address.country_id);
        }
        var billing_phone_input = document.querySelector(
            "#billing_address_phone_number"
          ),
          errorMsgBilling = document.querySelector("#error-msg-billing"),
          validMsgBilling = document.querySelector("#valid-msg-billing");

        var errorMap = [
          "Invalid number",
          "Invalid country code",
          "Too short",
          "Too long",
          "Invalid number",
        ];
        var iti = window.intlTelInput(billing_phone_input, {
          initialCountry: "us",
          nationalMode: true,
          utilsScript:
            "/admin-theme/plugins/intl-tel-input/build/js/utils.js?1638200991544",
        });
        var reset = function () {
          billing_phone_input.classList.remove("error");
          errorMsgBilling.innerHTML = "";
          errorMsgBilling.classList.add("hidden");
          validMsgBilling.classList.add("hidden");
        };
        billing_phone_input.addEventListener("blur", function () {
          reset();
          if (billing_phone_input.value.trim()) {
            if (iti.isValidNumber()) {
              validMsgBilling.classList.remove("hidden");
            } else {
              billing_phone_input.classList.add("error");
              var errorCode = iti.getValidationError();
              errorMsgBilling.innerHTML = errorMap[errorCode];
              errorMsgBilling.classList.remove("hidden");
            }
          }
          $scope.billing_address.phone_number = iti.getNumber();
        });
        billing_phone_input.addEventListener("change", reset);
        billing_phone_input.addEventListener("keyup", reset);

        if (document.querySelector("#shipping_address_phone_number")) {
          var shipping_phone_input = document.querySelector(
              "#shipping_address_phone_number"
            ),
            errorMsgShipping = document.querySelector("#error-msg-shipping"),
            validMsgShipping = document.querySelector("#valid-msg-shipping");

          var iti_shipping = window.intlTelInput(shipping_phone_input, {
            initialCountry: "us",
            nationalMode: true,
            utilsScript:
              "/admin-theme/plugins/intl-tel-input/build/js/utils.js?1638200991544",
          });
          var resetShipping = function () {
            shipping_phone_input.classList.remove("error");
            errorMsgShipping.innerHTML = "";
            errorMsgShipping.classList.add("hidden");
            validMsgShipping.classList.add("hidden");
          };
          shipping_phone_input.addEventListener("blur", function () {
            resetShipping();
            if (shipping_phone_input.value.trim()) {
              if (iti_shipping.isValidNumber()) {
                validMsgShipping.classList.remove("hidden");
              } else {
                shipping_phone_input.classList.add("error");
                var errorCode = iti_shipping.getValidationError();
                errorMsgShipping.innerHTML = errorMap[errorCode];
                errorMsgShipping.classList.remove("hidden");
              }
            }
            $scope.shipping_address.phone_number = iti_shipping.getNumber();
          });
          shipping_phone_input.addEventListener("change", resetShipping);
          shipping_phone_input.addEventListener("keyup", resetShipping);
        }
      });
    },
  ]);
}.call(this));

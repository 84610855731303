(function () {
  app.controller("DoSurveysCtrl", [
    "$scope",
    "$timeout",
    function ($scope, $timeout) {
      $scope.surveys = [];

      $scope.init = function () {};

      $scope.previousStep = function (survey_idx, question_idx) {
        var survey = $scope.surveys[survey_idx];

        if (question_idx > 0) {
          var question = survey.questions[question_idx];
          question.visible = false;
          question_idx -= 1;
          question = survey.questions[question_idx];
          question.visible = true;
        } else if (survey_idx > 0) {
          var survey = $scope.surveys[survey_idx];
          survey.visible = false;
          survey_idx -= 1;
          var survey = $scope.surveys[survey_idx];
          survey.visible = true;
        }
      };

      $scope.nextStep = function (survey_idx, question_idx) {
        var survey = $scope.surveys[survey_idx];
        var questions_length = survey.questions.length;

        if (question_idx < questions_length - 1) {
          var question = survey.questions[question_idx];
          question.visible = false;
          question_idx += 1;
          question = survey.questions[question_idx];
          question.visible = true;
        } else {
          var survey_length = $scope.surveys.length;

          if (survey_idx < survey_length - 1) {
            var survey = $scope.surveys[survey_idx];
            survey.visible = false;
            survey_idx += 1;
            var survey = $scope.surveys[survey_idx];
            survey.visible = true;
          }
        }
      };

      $scope.chooseMultiAnswers = function (question, answer) {
        if (question.answer == null || question.answer == "") {
          question.answer = [];
        }

        var idx = question.answer.indexOf(answer.id);
        if (idx < 0) {
          question.answer.push(answer.id);
        } else {
          question.answer.splice(idx, 1);
        }
      };

      $scope.submitSurvey = function () {
        console.log(1);
        var opt = { surveys: angular.toJson($scope.surveys) };

        $.post($scope.submit_url, opt, function () {});
      };
    },
  ]);
}.call(this));

(function () {
  app.controller("CreditCardCtrl", [
    "$scope",
    "$timeout",
    function ($scope, $timeout) {
      $scope.errorMessage = "";
      $scope.card_number = "";
      $scope.cvc = "";
      $scope.token = "";
      $scope.expiration = "";
      $scope.user_obj = {};

      $scope.init = function () {};

      $scope.validateSubmitButtonStatus = function () {
        var result = false;
        $scope.errorMessage = "";

        var card_number = $("#card_number").hasClass("complete");
        if (!card_number) {
          $scope.errorMessage = "Card Number is invalid";
          return result;
        }

        var card_expiration = $("#card_expiration").hasClass("complete");
        if (!card_expiration) {
          $scope.errorMessage = "Card Expiration is invalid";
          return result;
        }

        var card_cvv = $("#card_cvv").hasClass("complete");
        if (!card_cvv) {
          $scope.errorMessage = "Card CVV is invalid";
          return result;
        }

        result = true;
        $scope.errorMessage = "";
        return result;
      };

      $scope.submit = function (e) {
        if ($scope.validateSubmitButtonStatus()) {
          target = $(e.target).parents("form#credit_card");

          $(".loading").fadeIn();
          var name =
            $scope.user_obj.first_name + " " + $scope.user_obj.last_name;
          var token_data = { name: name };

          stripe
            .createToken($scope.card_number, token_data)
            .then(function (result) {
              $timeout(function () {
                if (result.error) {
                  $scope.errorMessage = result.error.message;
                  $(".loading").fadeOut();
                } else {
                  $scope.token = result.token.id;
                  $scope.last4_digit = result.token.card.last4;
                  $("[name=stripe_token]").val($scope.token);
                  $("[name=last4_digit]").val($scope.last4_digit);
                  target.submit();
                }
              });
            });
        } else {
          $(".loading").fadeOut();
        }
      };
    },
  ]);
}.call(this));

(function () {
  app.controller("OrderModifyAddonsCtrl", [
    "$scope",
    "$timeout",
    "$analytics",
    "$http",
    "$interval",
    function ($scope, $timeout, $analytics, $http, $interval) {
      $scope.NAV_OVERVIEW = "Overview";
      $scope.NAV_PAYMENTS = "Payments";
      $scope.NAV_ITEMS = [$scope.NAV_OVERVIEW, $scope.NAV_PAYMENTS];
      $scope.ADD_ON_KEY = "add-on";
      $scope.INCLUSION_KEY = "inclusion";
      $scope.TITLE_ADD_ADDONS = "Add Add-Ons";
      $scope.TITLE_EDIT_ADDONS = "Edit Add-Ons";
      $scope.TITLE_DELETE_ADDONS = "Delete Add-Ons";
      $scope.TITLE_AVAILABLE_ADDONS = "Available Add-Ons";
      $scope.TITLE_ADD_INCLUSIONS = "Add Inclusions";
      $scope.TITLE_EDIT_INCLUSIONS = "Edit Inclusions";
      $scope.TITLE_DELETE_INCLUSIONS = "Delete Inclusions";
      $scope.TITLE_AVAILABLE_INCLUSIONS = "Available Inclusions";
      $scope.TITLE_MAP = {
        [$scope.ADD_ON_KEY]: {
          add: $scope.TITLE_ADD_ADDONS,
          edit: $scope.TITLE_EDIT_ADDONS,
          delete: $scope.TITLE_DELETE_ADDONS,
          available: $scope.TITLE_AVAILABLE_ADDONS,
        },
        [$scope.INCLUSION_KEY]: {
          add: $scope.TITLE_ADD_INCLUSIONS,
          edit: $scope.TITLE_EDIT_INCLUSIONS,
          delete: $scope.TITLE_DELETE_INCLUSIONS,
          available: $scope.TITLE_AVAILABLE_INCLUSIONS,
        },
      };
      $scope.EVENT_CURRENCY = window.event_currency || "USD";

      $scope.initValues = () => {
        $scope.currentNav = $scope.NAV_ITEMS[0];
        $scope.addonId = { value: "" };
        $scope.addonInfo = {};
        $scope.refund = true;
        $scope.originalTotalCosts = 0;
        $scope.availableAddonOptions = [];
        $scope.totalQuantity = 0;
        $scope.originalTotalQuantity = 0;
        $scope.travelers = [];
        $scope.originalTravelers = [];
        $scope.fee = { value: 0 };
        $scope.discount = { value: 0 };
        $scope.reasonCode = { value: "" };
        $scope.isQuantityWarningVisible = false;
      };

      $scope.$on(
        "modifyAddonsDrawer.beforeShow",
        async (_, { productType, type, addonId }) => {
          $scope.initValues();
          $scope.productType = productType || $scope.ADD_ON_KEY;
          $scope.drawerTitle = $scope.TITLE_MAP[$scope.productType][type] || "";
          addonId = `${addonId}`;
          await $scope.initOverview({ addonId });
          await $scope.initAddonOptions({ addonId });
          await $scope.initPayments();
          switch (type) {
            case "add":
              if ($scope.isSplitOrder) {
                $scope.travelers.forEach((t) => {
                  $scope.addTravelerAddonQuantity(t);
                });
              } else {
                $scope.totalQuantity = $scope.roomMembers?.length || 0;
              }
              break;
            default:
              break;
          }
          $scope.$apply();
        }
      );

      $scope.$watch("totalQuantity", (newVal, _) => {
        if ($scope.isAdd() && $scope.roomMembers) {
          $scope.isQuantityWarningVisible =
            newVal !== ($scope.roomMembers.length || 0);
        }
      });

      $scope.initOverview = async ({ addonId }) => {
        if ($scope.isAdd()) return;
        const getAddonDetailsRes = await window.orderModifyApi.getAddonDetails({
          product_type: $scope.productType,
          order_number: $scope.order.order_number,
          addon_id: addonId,
        });

        $scope.travelers =
          getAddonDetailsRes?.split_orders_and_allocations?.map(
            (splitOrderAndAllocation) => {
              return {
                ...splitOrderAndAllocation.split_order,
                name: splitOrderAndAllocation.split_order?.member_name,
                quantity: splitOrderAndAllocation.allocated_quantity,
                addonOriginalUnitPrice:
                  splitOrderAndAllocation.original_unit_price,
                total:
                  (splitOrderAndAllocation.allocated_quantity || 0) *
                  (splitOrderAndAllocation.original_unit_price || 0),
              };
            }
          ) ?? [];
        $scope.originalTravelers = $.extend(true, [], $scope.travelers);
        $scope.addonInfo = getAddonDetailsRes?.addon ?? {};
        $scope.addonInfo.unit_price = getAddonDetailsRes?.unit_price ?? 0;
        $scope.addonInfo.original_unit_price =
          getAddonDetailsRes?.original_unit_price ?? 0;
        $scope.totalQuantity = getAddonDetailsRes?.quantity ?? 0;
        $scope.originalTotalQuantity = getAddonDetailsRes.quantity;
        $scope.originalTotalCosts =
          getAddonDetailsRes.quantity * getAddonDetailsRes.original_unit_price;
      };

      $scope.initAddonOptions = async ({ addonId }) => {
        let options = [];

        if ($scope.isAdd()) {
          const getSupportAddonsRes =
            await window.orderModifyApi.getSupportAddons({
              product_type: $scope.productType,
              order_number: $scope.order.order_number,
            });

          options =
            getSupportAddonsRes?.addons?.map((addon, index) => {
              return {
                label: addon.name,
                value: `${addon.id}`,
                unitPrice: getSupportAddonsRes?.addon_unit_prices?.[index] ?? 0,
              };
            }) ?? [];
          $scope.travelers =
            getSupportAddonsRes?.split_orders?.map((splitOrder) => {
              return {
                ...splitOrder,
                name: splitOrder.member_name,
                quantity: 0,
                addonOriginalUnitPrice: 0,
                total: 0,
              };
            }) ?? [];
          $scope.originalTravelers = $.extend(true, [], $scope.travelers);
          $scope.addonId.value = `${options[0]?.value || ""}`;
        }

        $scope.availableAddonOptions = options;

        if ($scope.isEdit() || $scope.isDelete()) {
          $scope.availableAddonOptions.push({
            label: $scope.addonInfo.name,
            value: addonId,
            unitPrice: parseFloat($scope.addonInfo.unit_price) || 0,
          });
          $scope.addonId.value = addonId;
        }
      };

      $scope.initPayments = async () => {
        $scope.roomDetails = await window.orderModifyApi.getOrderRoomMembers({
          order_number: $scope.order.order_number,
        });
        $scope.roomMembers = $scope.roomDetails.reduce(
          (previousValue, currentValue) => {
            previousValue.push(...currentValue.members);
            return previousValue;
          },
          []
        );
        $scope.buyer = $scope.roomMembers.find(
          (m) => m.user_id === $scope.order.buyer_id
        );
        $scope.memberMap = $scope.roomMembers.reduce(
          (previousValue, currentValue) => {
            const { total_amount = 0 } = currentValue || {};
            previousValue[$scope.getMemberKey(currentValue)] = {
              cost: total_amount,
              total_amount,
            };
            if (
              currentValue.payer_name &&
              !previousValue[$scope.getPayerKey(currentValue)]
            ) {
              previousValue[$scope.getPayerKey(currentValue)] = {
                cost: total_amount,
                total_amount,
              };
            }
            return previousValue;
          },
          {}
        );
      };

      $scope.getMemberKey = (roomMember) => {
        return roomMember.room_member_id;
      };

      $scope.getPayerKey = (roomMember) => {
        if ($scope.isSplitOrder) {
          return roomMember.payer_room_member_id;
        }
        return $scope.buyer?.room_member_id;
      };

      $scope.switchNav = (navItem) => {
        $scope.recalculatePayments();
        $scope.currentNav = navItem;
      };

      $scope.recalculatePayments = () => {
        $scope.calculateEachRoomMemberCost();
      };

      $scope.calculateEachRoomMemberCost = () => {
        const sharedDiscount = Number(
          (
            $scope.discount.value / ($scope.roomMembers.length || 1) || 0
          ).toFixed(2)
        );
        const sharedFee = Number(
          ($scope.fee.value / ($scope.roomMembers.length || 1) || 0).toFixed(0)
        );

        // Set each member discount
        $scope.roomMembers.forEach((roomMember) => {
          const memberKey = $scope.getMemberKey(roomMember);
          const traveler = $scope.travelers.find(
            (t) => $scope.getMemberKey(t) === memberKey
          );
          let travelerAddonPriceDiff = 0;
          if ($scope.isSplitOrder) {
            travelerAddonPriceDiff = $scope.getTravelerAddonPriceDiff(traveler);
          } else {
            travelerAddonPriceDiff =
              $scope.getTotalAddonPriceDiff() / $scope.roomMembers.length;
          }
          $scope.memberMap[memberKey].fee = sharedFee;
          $scope.memberMap[memberKey].discount = sharedDiscount;
          $scope.memberMap[memberKey].cost =
            $scope.memberMap[memberKey].total_amount + travelerAddonPriceDiff;
        });

        // Transfer member discount to payer
        $scope.roomMembers.forEach((roomMember) => {
          const payerKey = $scope.getPayerKey(roomMember);
          const memberKey = $scope.getMemberKey(roomMember);
          if (!payerKey || payerKey === memberKey) return;
          $scope.memberMap[payerKey].fee +=
            $scope.memberMap[memberKey]?.fee || 0;
          $scope.memberMap[payerKey].discount +=
            $scope.memberMap[memberKey]?.discount || 0;
          $scope.memberMap[payerKey].cost +=
            $scope.memberMap[memberKey]?.cost || 0;
          $scope.memberMap[memberKey].fee = 0;
          $scope.memberMap[memberKey].discount = 0;
          $scope.memberMap[memberKey].cost = 0;
        });
      };

      $scope.getTotalCostCount = (roomMember) => {
        const {
          cost = 0,
          fee = 0,
          discount = 0,
        } = $scope.memberMap[$scope.getMemberKey(roomMember)] || {};
        return cost + fee - discount;
      };

      $scope.getNewPaymentCount = (roomMember) => {
        const totalCost = $scope.getTotalCostCount(roomMember);
        const paidToDate = Number(roomMember.paid_to_date || 0);
        const leftInstallments = roomMember.left_installments || 1;
        if (totalCost === 0) {
          return 0;
        }
        return (totalCost - paidToDate) / leftInstallments;
      };

      $scope.getCurrentAddon = () => {
        return (
          $scope.availableAddonOptions.find(
            (item) => item.value === $scope.addonId.value
          ) || {
            label: "Select Add-On",
            value: null,
            unitPrice: 0,
          }
        );
      };

      $scope.getTravelerAddonPriceDiff = (traveler) => {
        const originalTraveler = $scope.originalTravelers.find(
          (item) => item.id === traveler.id
        );
        const quantityDiff = traveler.quantity - originalTraveler.quantity;
        if (quantityDiff === 0) return 0;
        if (quantityDiff < 0)
          return originalTraveler.addonOriginalUnitPrice * quantityDiff;
        return $scope.getCurrentAddon().unitPrice * quantityDiff;
      };

      $scope.getTravelerAddonPrice = (traveler) => {
        const originalTraveler = $scope.originalTravelers.find(
          (item) => item.id === traveler.id
        );
        return (
          originalTraveler.total + $scope.getTravelerAddonPriceDiff(traveler)
        );
      };

      $scope.getTotalAddonPriceDiff = () => {
        if ($scope.isSplitOrder) {
          return $scope.travelers.reduce((accu, curr) => {
            accu += $scope.getTravelerAddonPriceDiff(curr);
            return accu;
          }, 0);
        }
        const quantityDiff =
          $scope.totalQuantity - $scope.originalTotalQuantity;
        if (quantityDiff === 0) return 0;
        if (quantityDiff < 0)
          return $scope.addonInfo.original_unit_price * quantityDiff;
        return $scope.getCurrentAddon().unitPrice * quantityDiff;
      };

      $scope.getTotalAddonPrice = () => {
        return $scope.originalTotalCosts + $scope.getTotalAddonPriceDiff();
      };

      $scope.getAddonAdditions = () => {
        return Math.max(
          0,
          $scope.getTotalAddonPrice() - $scope.originalTotalCosts
        );
      };

      $scope.getAddonRemovals = () => {
        if ($scope.isDelete()) return $scope.originalTotalCosts;
        return Math.max(
          0,
          $scope.originalTotalCosts - $scope.getTotalAddonPrice()
        );
      };

      $scope.minusTotalAddonQuantity = () => {
        if ($scope.totalQuantity > 0) {
          $scope.totalQuantity -= 1;
        }
      };

      $scope.addTotalAddonQuantity = () => {
        if ($scope.totalQuantity < 100) {
          $scope.totalQuantity += 1;
        }
      };

      $scope.minusTravelerAddonQuantity = (traveler) => {
        if (traveler.quantity > 0) {
          traveler.quantity -= 1;
          $scope.totalQuantity -= 1;
        }
      };

      $scope.addTravelerAddonQuantity = (traveler) => {
        if (traveler.quantity < 100) {
          traveler.quantity += 1;
          $scope.totalQuantity += 1;
        }
      };

      $scope.confirm = async () => {
        const currentPrice = $scope.getTotalAddonPrice();
        const shouldShowRefundModalOnNotSplitPayment =
          $scope.isEdit() &&
          !$scope.isSplitOrder &&
          currentPrice < $scope.originalTotalCosts;
        const shouldShowRefundModalOnSplitPayment =
          $scope.isEdit() &&
          $scope.isSplitOrder &&
          $scope.travelers.some(
            (traveler, i) =>
              traveler.quantity < $scope.originalTravelers?.[i]?.quantity
          );
        const shouldShowRefundModal =
          $scope.isDelete() ||
          shouldShowRefundModalOnNotSplitPayment ||
          shouldShowRefundModalOnSplitPayment;

        if (shouldShowRefundModal) {
          $("#drawer-addon-modal").modal("show");
          $(".loading").fadeOut();
          return;
        }
        await $scope.submit();
      };

      $scope.submit = async () => {
        await window.orderModifyApi.updateAddon({
          product_type: $scope.productType,
          order_number: $scope.order.order_number,
          addon_id: $scope.addonId.value,
          split_orders: $scope.travelers.map((traveler) => ({
            split_order_id: traveler.id,
            allocated_quantity: $scope.isDelete() ? 0 : traveler.quantity,
          })),
          quantity: $scope.isDelete() ? 0 : $scope.totalQuantity,
          refund: !$scope.isAdd() && $scope.refund,
          discount_amount: $scope.discount.value || undefined,
          discount_reason_code: $scope.reasonCode.value || undefined,
          fee: $scope.fee.value || undefined,
        });
        toastr.success("Successfully!");
        $scope.updateAddonTable();
      };

      $scope.updateAddonTable = () => {
        window.location.reload();
      };

      $scope.isAdd = () => {
        return $scope.drawerTitle === $scope.TITLE_MAP[$scope.productType]?.add;
      };

      $scope.isEdit = () => {
        return (
          $scope.drawerTitle === $scope.TITLE_MAP[$scope.productType]?.edit
        );
      };

      $scope.isDelete = () => {
        return (
          $scope.drawerTitle === $scope.TITLE_MAP[$scope.productType]?.delete
        );
      };

      $scope.isAddDisabled = () => {
        return $scope.isAdd() && !$scope.availableAddonOptions.length;
      };

      $scope.isAddonOptionsSelectDisabled = () => {
        return $scope.isEdit() || $scope.isDelete() || $scope.isAddDisabled();
      };

      $scope.isTotalQuantityDisabled = () => {
        return (
          $scope.isDelete() || $scope.isSplitOrder || $scope.isAddDisabled()
        );
      };

      $scope.isQuantityDisabled = () => {
        return $scope.isDelete() || $scope.isAddDisabled();
      };

      $scope.capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      };
    },
  ]);
}.call(this));
